/* Layout Styles */
.layout {
  min-height: 100vh;
  background-color: none;
}

.site-layout-content {
  background: #fff;
  margin-bottom: 50px;
}

.ant-layout-content {
  padding: 0px 10px !important;
}

/* Sidebar Styles */
.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #f8f9fa; /* Light sidebar background */
  color: #343a40;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-right: 1px solid #e0e0e0;
}

.sidebar .logo {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: center;
  color: #007bff;
}

.sidebar a {
  color: #343a40;
  text-decoration: none;
  font-size: 16px;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  display: block;
  transition: background-color 0.3s ease;
}

.sidebar a:hover {
  background-color: #e9ecef;
}

.sidebar .btn {
  margin-top: auto;
  background-color: #007bff;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s ease;
}

.sidebar .btn:hover {
  background-color: #0056b3;
}

/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.layout .header .ant-menu-item {
  display: inline-block;
  margin-right: 16px;
}

.ant-menu-item {
  padding: 0px !important;
  background-color: transparent !important;
}

/* Product Card Styles */
.product-card {
  background-color: #e0f2fe; /* Light blue background */
  color: #172554; /* Dark text */
  border: 1px solid #d1e7f8; /* Subtle border for cards */
  border-radius: 8px; /* Rounded corners */
  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.2s, box-shadow 0.2s;
}

.product-card:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.product-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 8px;
}

.status-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
}

.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.status-badge.online {
  background-color: #4caf50; /* Green for online */
}

.status-badge.offline {
  background-color: #f44336; /* Red for offline */
}

.status-badge.checking {
  background-color: #ffc107; /* Yellow for checking */
}

.last-checked {
  font-size: 12px;
  color: #888; /* Grayed-out text */
}

.card-actions {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.btn-open {
  background-color: #fb923c; /* Orange button */
  color: white;
  border: none;
}

.btn-open:hover {
  background-color: #f97316; /* Darker orange on hover */
}

.btn-edit {
  background-color: #60a5fa; /* Light blue */
  color: white;
}

.btn-edit:hover {
  background-color: #3b82f6; /* Darker blue on hover */
}

.btn-delete {
  background-color: #ef4444; /* Red button */
  color: white;
}

.btn-delete:hover {
  background-color: #dc2626; /* Darker red on hover */
}

/* Grid Styling */
.ant-row {
  margin-top: 20px;
  column-gap: 10px;
}

.ant-col {
  padding: 8px;
}

/* Footer Styles */
.footer {
  text-align: center;
  margin-top: 50px;
  padding: 16px 0;
  background-color: #f8f9fa;
  border-top: 1px solid #e0e0e0;
  color: #333;
}

.footer a {
  color: #007bff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

/* Miscellaneous Styles */
.ant-btn {
  background-color: #fb923c;
  color: #fff;
}

.ant-btn:hover {
  background-color: #f97316;
}

.ant-card-meta-title {
  font-size: 1.5em;
}

.ant-card-meta-description {
  margin-top: 1em;
}