body {
  margin: 0;
  font-family: 'Space Mono' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f8fb;
}

html {
 font-family: 'Space Mono' !important;
}

code {
  font-family: 'Space Mono' !important;
}


