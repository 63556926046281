/* frontend/src/Login.css */
.login-form {
  
}

.h2, h2, .h3, h3{
    font-family: 'Space Mono', monospace; /* Use Space Mono for headings as well */
    font-size: 1.25rem;
    line-height: 1.75rem;
}

/* frontend/src/Login.css */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: 'Space Mono', monospace !important; /* Use Space Mono, with fallback to generic monospace */
}

.login-card {
  width: 400px; /* Adjust width as needed */
  padding: 30px;
}

.logo-wrapper {
  text-align: center; /* Center logo and text */
  
}

.login-logo {
  height: 175px; /* Adjust height as needed */
  
}
