/* frontend/src/Dashboard.css */
.layout {
  min-height: 100vh; /* Make layout take up full viewport height */
  background-color: none;
}
/*
FB923C
E0F2FE
*/
.product-card{
 background-color: #E0F2FE;
 color:#172554;
 text-transform: capitalize;
}

.ant-card-head{
  border-bottom: 2px solid #fff !important;
}

.site-layout-content{
  background: transparent !important;
  margin-bottom: 50px;
}

.ant-card-head-title{
  color:#172554;
}

.ant-btn{
  background-color: #FB923C;
  color:#fff;
}


.status-done {
  color: green; /* Green for Done status */
}

.status-delayed {
  color: red; /* Red for Delayed status */
}

.ant-btn-primary[disabled] {
  background-color: #f5f5f5; /* Light gray for disabled state */
  border-color: #d9d9d9; 
  color: #999;
}

/* Style for "Delay" button */
.ant-btn-primary.ant-btn-danger[disabled] {
  background-color: #f5f5f5; /* Light gray for disabled state */
  border-color: #d9d9d9; 
  color: #999;
}

.ant-btn-primary.ant-btn-danger {
  background-color: #f5222d; /* Red color */
  border-color: #f5222d;
}

/* frontend/src/Goals.css */
.goal-list {
  background: #fff; /* White background for list */
  border-radius: 4px; /* Rounded corners */
  padding: 16px; /* Add padding to the list */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.goal-list .ant-list-item-meta-title {
  font-weight: bold; /* Make the goal titles bold */
}

.goal-description {
  margin-bottom: 8px; /* Add some space between description and due date */
}

.due-date {
  font-size: 12px; /* Smaller font size for due date */
  color: #888; /* Grayed-out color for due date */
}


.ant-btn-dangerous{
  background-color: #fff !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #fff; /* White background for header */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}


.layout .header {
  /* ... your existing header styles ... */
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Align items to the edges */
  align-items: center; /* Vertically center items */
}



/* Remove dropdown styles */
.layout .header .ant-dropdown-menu {
  display: none; /* Hide the dropdown completely */
}

/* Style menu items as horizontal instead of vertical */
.layout .header .ant-menu-item {
  display: inline-block; /* Display items horizontally */
  margin-right: 16px; /* Add spacing between items */
}

/* Style the Switch component to fit within the menu item */
.layout .header .ant-menu-item .ant-switch {
  margin: 0; /* Remove default margins */
}


.logo {
  font-size: 20px; /* Larger logo font */
  font-weight: bold;
  color: #333; /* Darker logo color */
}

.logo-container {
  display: flex;
  align-items: center; /* Align logo and text vertically */
}

.logo-image {
  height: 100px; /* Adjust the height as needed */
  margin-right: 0px; /* Add some spacing between logo and text */
}

.logo-text {
  font-size: 20px;
  font-weight: bold;
  color: #333; /* Darker color for text */
}

.site-layout-content {
  background: #fff; /* White background for content area */
  
 
}

.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}

.status-badge.online {
  background-color: #4CAF50; /* Green for online */
  color: white;
}

.status-badge.offline {
  background-color: #f44336; /* Red for offline */
  color: white;
}

.status-container {
  display: flex;
  justify-content: space-between; /* Align items to opposite ends */
  align-items: center;
}

.last-checked {
  font-size: 12px; /* Smaller font size */
  color: #888; /* Grayed-out color */
}

/* Table Styling */
.ant-table {
  border: 1px solid #f0f0f0; /* Border around the table */
}

.ant-table-thead > tr > th {
  background-color: #1B325F !important;
  color:#fff !important;
}

.ant-table-cell {
  color:#1B325F !important;
}

/* Form Styling */
.ant-form-item-label > label {
  font-weight: bold; /* Make form labels bold */
}